import React from 'react'

function Features() {
  return (
    <section id="features" className="features">
      <div className="container" data-aos="fade-up">

        <div className="row">
          <div className="image col-lg-6 image-f" data-aos="fade-right"></div>
          <div className="col-lg-6" data-aos="fade-left" data-aos-delay="100">
            <div className="icon-box mt-5 mt-lg-0" data-aos="zoom-in" data-aos-delay="150">
              <i className="bx bx-network-chart"></i>
              <h4>Rapid development</h4>
              <p>Speeding up the development process while at the same time catering for client requirements.</p>
            </div>
            <div className="icon-box mt-5" data-aos="zoom-in" data-aos-delay="150">
              <i className="bx bx-abacus"></i>
              <h4>Emerging technoligies</h4>
              <p>We ensure to keep up in pace with the latest trends in the market for provision of up-to-date services and products.</p>
            </div>
            <div className="icon-box mt-5" data-aos="zoom-in" data-aos-delay="150">
              <i className="bx bx-money"></i>
              <h4>Affordability</h4>
              <p>Value for your cash. At the most affordable prices the highest quality products at offer.</p>
            </div>
            <div className="icon-box mt-5" data-aos="zoom-in" data-aos-delay="150">
              <i className="bi-award-fill"></i>
              <h4>Expertise</h4>
              <p>Get an opportunity to meet a team of highly experienced proffessionals.</p>
            </div>
          </div>
        </div>

      </div>
    </section>
  )
}

export default Features