import React from 'react';
// Import Swiper React components
import { Autoplay, Pagination  } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';


const Testimonials = () => {  
  return (
    <section id="testimonials" className="testimonials">
      <div className="container" data-aos="zoom-in">

        <div className="testimonials-slider swiper" data-aos="fade-up" data-aos-delay="100">
          <div className="swiper-wrapper">

          <Swiper
            // install Swiper modules
            modules={[Autoplay, Pagination]}
            speed = {400}
            loop = {true}
            slidesPerView = {1}
            autoplay = {{
              delay: 5000,
              disableOnInteraction: false
            }}
            pagination={
                        {
                el: '.swiper-pagination',
                type: 'bullets',
                clickable: true
            }
            }
            >

            <SwiperSlide>  
            <div className="swiper-slide">
              <div className="testimonial-item">
                <img src={require("../../assets/img/testimonials/testimonials-1.jpg")} className="testimonial-img" alt=""/>
                <h3>David Gitau</h3>
                <h4>CEO &amp; Co-founder</h4>
                <p>
                  <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                  The best company that offers the best solutions to your technological problems. This is the only place in the market to get products with an assurance of quality and satisfaction.
                  <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                </p>
              </div>
            </div>
            </SwiperSlide>
            
            <SwiperSlide>  
            <div className="swiper-slide">
              <div className="testimonial-item">
                <img src={require("../../assets/img/testimonials/testimonials-2.jpg")} className="testimonial-img" alt=""/>
                <h3>Erick Muturi</h3>
                <h4>CTO &amp; Co-founder</h4>
                <p>
                  <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                  Export tempor illum tamen malis malis eram quae irure esse labore quem cillum quid cillum eram malis quorum velit fore eram velit sunt aliqua noster fugiat irure amet legam anim culpa.
                  <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                </p>
              </div>
            </div>
            </SwiperSlide>

            <SwiperSlide>  
            <div className="swiper-slide">
              <div className="testimonial-item">
                <img src={require("../../assets/img/testimonials/testimonials-3.jpg")} className="testimonial-img" alt=""/>
                <h3>Jena Karlis</h3>
                <h4>Store Owner</h4>
                <p>
                  <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                  Enim nisi quem export duis labore cillum quae magna enim sint quorum nulla quem veniam duis minim tempor labore quem eram duis noster aute amet eram fore quis sint minim.
                  <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                </p>
              </div>
            </div>
            </SwiperSlide>

            <SwiperSlide>  
            <div className="swiper-slide">
              <div className="testimonial-item">
                <img src={require("../../assets/img/testimonials/testimonials-4.jpg")} className="testimonial-img" alt=""/>
                <h3>Matt Brandon</h3>
                <h4>Freelancer</h4>
                <p>
                  <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                  Fugiat enim eram quae cillum dolore dolor amet nulla culpa multos export minim fugiat minim velit minim dolor enim duis veniam ipsum anim magna sunt elit fore quem dolore labore illum veniam.
                  <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                </p>
              </div>
            </div>
            </SwiperSlide>

            <SwiperSlide>
            <div className="swiper-slide">
              <div className="testimonial-item">
                <img src={require("../../assets/img/testimonials/testimonials-5.jpg")} className="testimonial-img" alt=""/>
                <h3>John Larson</h3>
                <h4>Entrepreneur</h4>
                <p>
                  <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                  Quis quorum aliqua sint quem legam fore sunt eram irure aliqua veniam tempor noster veniam enim culpa labore duis sunt culpa nulla illum cillum fugiat legam esse veniam culpa fore nisi cillum quid.
                  <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                </p>
              </div>
            </div>
            </SwiperSlide>
          </Swiper>
          </div>
          <div className="swiper-pagination"></div>
        </div>

      </div>
    </section>
  )
}

export default Testimonials