import React from 'react';
import abt from "../../assets/img/about.jpg";

function About() {
  return (
    <section id="about" className="about">
      <div className="container" data-aos="fade-up">

        <div className="row">
          <div className="col-lg-6 order-1 order-lg-2" data-aos="fade-left" data-aos-delay="100">
            <img src={abt} className="img-fluid" alt=""/>
          </div>
          <div className="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 content" data-aos="fade-right" data-aos-delay="100">
            <h3>The next dream technologies solutions company limited.</h3>
            <p className="fst-italic">
              Helping our clients realize, unleash and actualize their technology dreams.
              Here's why our customers prefer us.
            </p>
            <ul>
              <li><i className="ri-check-double-line"></i> We apply the latest trends in the market to deliver amazing products.</li>
              <li><i className="ri-check-double-line"></i> Quality services with the most affordable prices.</li>
              <li><i className="ri-check-double-line"></i> Timely delivery to cater for your needs assured by our highly experienced experts.</li>
              <li><i className="ri-check-double-line"></i> Effective customer care service thanks to 24/7 on reach helplines.</li>
            </ul>
            <p>
              Choose us to be part of your technology journey. We are privildged to be there when needed to 
              help in solving that problem you have regardless of its complexity! Reach out for any project anytime!
            </p>
          </div>
        </div>

      </div>
    </section>
  )
}

export default About