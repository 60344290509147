import React from 'react'

function Footer() {
  return (
    <footer id="footer">
    <div className="footer-top">
      <div className="container">
        <div className="row">

          <div className="col-lg-3 col-md-6">
            <div className="footer-info">
              <h3>TnD<span>.</span></h3>
              <p>
                Kahawa Wendani off Thika Road <br/>
                NAIROBI, KENYA<br/><br/>
                <strong>Phone:</strong> +254 110 859 356<br/>
                <strong>Email:</strong> info.tndtechke@gmail.com<br/>
              </p>
              <div className="social-links mt-3">
                <a href="http://twitter.com/tndtechke" className="twitter"><i className="bx bxl-twitter"></i></a>
                <a href="http://facebook.com/tndtechke" className="facebook"><i className="bx bxl-facebook"></i></a>
                <a href="http://instagram.com/tndtechke" className="instagram"><i className="bx bxl-instagram"></i></a>
                <a href="/" className="google-plus"><i className="bx bxl-skype"></i></a>
                <a href="http://linkedin.com/tndtechke" className="linkedin"><i className="bx bxl-linkedin"></i></a>
              </div>
            </div>
          </div>

          <div className="col-lg-2 col-md-6 footer-links">
            <h4>Useful Links</h4>
            <ul>
              <li><i className="bx bx-chevron-right"></i> <a href="/">Home</a></li>
              <li><i className="bx bx-chevron-right"></i> <a href="/">About us</a></li>
              <li><i className="bx bx-chevron-right"></i> <a href="/">Services</a></li>
              <li><i className="bx bx-chevron-right"></i> <a href="/">Terms of service</a></li>
              <li><i className="bx bx-chevron-right"></i> <a href="/">Privacy policy</a></li>
            </ul>
          </div>

          <div className="col-lg-3 col-md-6 footer-links">
            <h4>Our Services</h4>
            <ul>
              <li><i className="bx bx-chevron-right"></i> <a href="/">Software Design</a></li>
              <li><i className="bx bx-chevron-right"></i> <a href="/">Web Development</a></li>
              <li><i className="bx bx-chevron-right"></i> <a href="/">Product Management</a></li>
              <li><i className="bx bx-chevron-right"></i> <a href="/">Testing & Automation</a></li>
              <li><i className="bx bx-chevron-right"></i> <a href="/">Graphic Design</a></li>
            </ul>
          </div>

          <div className="col-lg-4 col-md-6 footer-newsletter">
            <h4>Our Newsletter</h4>
            <p>Don't agree to miss out on our latest updates! Leave your e-mail below for updates!</p>
            <form action="" method="post">
              <input type="email" name="email"/><input type="submit" value="Subscribe"/>
            </form>

          </div>

        </div>
      </div>
    </div>

    <div className="container">
      <div className="copyright">
        &copy; Copyright <strong><span>TND</span></strong>. All Rights Reserved
      </div>
      <div className="credits">
        Designed by <a href="/">TND Technologies</a>
      </div>
    </div>
  </footer>
  )
}

export default Footer