import React from 'react';

function Hero() {
  return (
      <section id="hero" className="d-flex align-items-center justify-content-center">
        <div className="container" data-aos="fade-up">

          <div className="row justify-content-center" data-aos="fade-up" data-aos-delay="150">
            <div className="col-xl-6 col-lg-8">
              <h1>Crazy Technology solutions with TND<span>.</span></h1>
              <h2>Unleash and actualize the next dream</h2>
            </div>
          </div>

          <div className="row gy-4 mt-5 justify-content-center" data-aos="zoom-in" data-aos-delay="250">
            <div className="col-xl-2 col-md-4">
              <div className="icon-box">
                <i className="bi-gear-wide-connected"></i>
                <h3><a href="/">System Design</a></h3>
              </div>
            </div>
            <div className="col-xl-2 col-md-4">
              <div className="icon-box">
                <i className="bx bx-desktop"></i>
                <h3><a href="/">Web Development</a></h3>
              </div>
            </div>
            <div className="col-xl-2 col-md-4">
              <div className="icon-box">
                <i className="bx bx-bot"></i>
                <h3><a href="/">Testing & Automation</a></h3>
              </div>
            </div>
            <div className="col-xl-2 col-md-4">
              <div className="icon-box">
                <i className="bi-ui-checks"></i>
                <h3><a href="/">Product Management</a></h3>
              </div>
            </div>
            <div className="col-xl-2 col-md-4">
              <div className="icon-box">
                <i className="bi-alexa"></i>
                <h3><a href="/">Graphic Design</a></h3>
              </div>
            </div>
          </div>

        </div>
      </section>
  )
}

export default Hero