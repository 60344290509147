import { useEffect } from 'react';

// npm i aos — save
// npm install bootstrap — save
// npm install boxicons --save
// import Swiper from 'swiper';
import AOS from "aos";
import "aos/dist/aos.css";
// Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css";
// Bootstrap Bundle JS
import "bootstrap/dist/js/bootstrap.bundle.min";
import 'bootstrap-icons/font/bootstrap-icons.css';
// import 'boxicons';
import "./assets/boxicons/css/boxicons.min.css"
import "./assets/css/style.css"
import "./assets/remixicon/remixicon.css"

import Footer from "./components/base/Footer";
import Header from "./components/base/Header";
import Home from "./components/home";

import { BrowserRouter as Router, Routes } from 'react-router-dom';
// import Modal from './components/base/Modal';


const App = () => {
     /**
     * Easy selector helper function
     */
    const select = (el, all = false) => {
      el = el.trim()
      if (all) {
        return [...document.querySelectorAll(el)]
      } else {
        // console.log(el)
        return document.querySelector(el)
      }
    }
    /**
     * Easy event listener function
     */
    const on = (type, el, listener, all = false) => {
      let selectEl = select(el, all)
      if (selectEl) {
        if (all) {
          selectEl.forEach(e => e.addEventListener(type, listener))
        } else {
          selectEl.addEventListener(type, listener)
        }
      }
    }
    /**
     * Easy on scroll event listener 
     */
    const onscroll = (el, listener) => {
      el.addEventListener('scroll', listener)
    }
    /**
   * Scrolls to an element with header offset
   */
    const scrollto = (el) => {
      let header = select('#header')
      let offset = header.offsetHeight

      let elementPos = select(el).offsetTop
      window.scrollTo({
        top: elementPos - offset,
        behavior: 'smooth'
      })
    }

    // AOS Effect
    useEffect(() => {
      /**
     * Animation on scroll
     */
    window.addEventListener('load', () => {
      AOS.init({
        duration: 1000,
        easing: "ease-in-out",
        once: true,
        mirror: false
      });
    });
      AOS.init();
      AOS.refresh();
    }, []);

    let x = 0
    useEffect(() => {
    /**
   * Mobile nav toggle
   */
    on('click', '.mobile-nav-toggle', function(e) {
      x++
      console.log(this.classList.value,x)
      if(x%2===0) return
      if(this.classList.contains("bi-list")) {
        select('#navbar').classList.add('navbar-mobile')
        this.classList.remove('bi-list')
        this.classList.add('bi-x')
      }
      else {
        this.classList.remove('bi-x')
        this.classList.add('bi-list')
        select('#navbar').classList.remove('navbar-mobile')
    }
    })
    })
    
    useEffect(() => {
      /**
   * Toggle .header-scrolled class to #header when page is scrolled
   */
  let selectHeader = select('#header')
  if (selectHeader) {
    const headerScrolled = () => {
      if (window.scrollY > 100) {
        selectHeader.classList.add('header-scrolled')
      } else {
        selectHeader.classList.remove('header-scrolled')
      }
    }
    window.addEventListener('load', headerScrolled)
    onscroll(document, headerScrolled)
  }
    /**
   * Back to top button
   */
    let backtotop = select('.back-to-top')
    if (backtotop) {
      const toggleBacktotop = () => {
        if (window.scrollY > 100) {
          backtotop.classList.add('active')
        } else {
          backtotop.classList.remove('active')
        }
      }
      window.addEventListener('load', toggleBacktotop)
      onscroll(document, toggleBacktotop)
    }
    
    /**
     * Mobile nav dropdowns activate
     */
    on('click', '.navbar .dropdown > a', function (e) {
      console.log('9ooooooooooooooooooooo')
      if (select('#navbar').classList.contains('navbar-mobile')) {
        e.preventDefault()
        this.nextElementSibling.classList.toggle('dropdown-active')
      }
    }, true)
    
    /**
     * Scrool with ofset on links with a class name .scrollto
     */
    on('click', '.scrollto', function(e) {
      if (select(this.hash)) {
        e.preventDefault()

        let navbar = select('#navbar')
        if (navbar.classList.contains('navbar-mobile')) {
          navbar.classList.remove('navbar-mobile')
          let navbarToggle = select('.mobile-nav-toggle')
          navbarToggle.classList.toggle('bi-list')
          navbarToggle.classList.toggle('bi-x')
        }
        scrollto(this.hash)
      }
    }, true)

    /**
     * Scroll with ofset on page load with hash links in the url
     */
    window.addEventListener('load', () => {
      if (window.location.hash) {
        if (select(window.location.hash)) {
          scrollto(window.location.hash)
        }
      }
    });

    /**
     * Preloader
     */
    let preloader = select('#preloader');
    if (preloader) {
      window.addEventListener('load', () => {
        preloader.remove()
      });
    }


    let navbarlinks = select('#navbar .scrollto', true)
    const navbarlinksActive = () => {
    let position = window.scrollY + 200
      navbarlinks.forEach(navbarlink => {
      // let pathn = `#${navbarlink.pathname.substr(1)}`
      //   if (!pathn) return
      //   if (pathn==="#") pathn = "#hero"
      // let section = select(pathn)
      if (!navbarlink.hash) return
      let section = select(navbarlink.hash)
      if (!section) return
      // if (!section) return
      if (position >= section.offsetTop && position <= (section.offsetTop + section.offsetHeight)) {
        navbarlink.classList.add('active')
      } else {
        navbarlink.classList.remove('active')
      }
    })
  }
  window.addEventListener('load', navbarlinksActive)
  onscroll(document, navbarlinksActive)
  
    const handleClick = () => {
      const element = document.activeElement
      const x = navbarlinks.find(n => n === element)
      if(!x) return
      navbarlinks.forEach(navbarlink => {
        if (element === navbarlink) {
          navbarlink.classList.add('active')
        } else {
        navbarlink.classList.remove('active')
      }
        
      })
    }
  window.addEventListener('click', handleClick)
  })
  return (
    <div className="App">
      <Header />
      {/* <Modal/> */}
      <Router>
        <Routes>
          {/* <Route path="*">
          </Route> */}
        </Routes>
      </Router>
      <Home/>
      <Footer/>
    </div>
  );
}

export default App;
