import React from 'react'

const Header = () => {
  return (
      <header id="header" className="fixed-top ">
    <div className="container d-flex align-items-center justify-content-lg-between">

      <h1 className="logo me-auto me-lg-0"><a href="/">TND<span>.</span></a></h1>
      {/* <!-- Uncomment below if you prefer to use an image logo --> */}
        {/* <a href="/" className="logo me-auto me-lg-0"><img src={require("../../assets/img/logo.png")} alt="" className="img-fluid"  /></a> */}

      <nav id="navbar" className="navbar order-last order-lg-0">
        <ul>
          <li><a className="nav-link scrollto active" href="#hero">Home</a></li>
          <li><a className="nav-link scrollto" href="#about">About</a></li>
          <li><a className="nav-link scrollto" href="#services">Services</a></li>
          {/* <li><a className="nav-link scrollto " href="#portfolio">Portfolio</a></li> */}
          <li><a className="nav-link scrollto" href="#team">Team</a></li>
          {/* <li className="dropdown"><a href="/"><span>Drop Down</span> <i className="bi bi-chevron-down"></i></a>
            <ul>
              <li><a href="/">Drop Down 1</a></li>
              <li className="dropdown"><a href="/"><span>Deep Drop Down</span> <i className="bi bi-chevron-right"></i></a>
                <ul>
                  <li><a href="/">Deep Drop Down 1</a></li>
                  <li><a href="/">Deep Drop Down 2</a></li>
                  <li><a href="/">Deep Drop Down 3</a></li>
                  <li><a href="/">Deep Drop Down 4</a></li>
                  <li><a href="/">Deep Drop Down 5</a></li>
                </ul>
              </li>
              <li><a href="/">Drop Down 2</a></li>
              <li><a href="/">Drop Down 3</a></li>
              <li><a href="/">Drop Down 4</a></li>
            </ul>
          </li> */}
          <li><a className="nav-link scrollto" href="#contact">Contact</a></li>
          </ul>
                    {/* <li className="list-inline-item"><a className="text-uppercase text-color p-sm-2 py-2 px-0 d-inline-block" to="#" data-toggle="modal" data-target="#loginModal">login</a></li> */}
        <i className="bi bi-list mobile-nav-toggle"></i>
      </nav>
      {/* <!-- .navbar --> */}

      <a href="#about" className="get-started-btn scrollto">Learn More.</a>

    </div>
  </header>
  )
}

export default Header