import React from 'react'

function Services() {
  return (
    <section id="services" class="services">
      <div class="container" data-aos="fade-up">

        <div class="section-title">
          <h2>Services</h2>
          <p>What we offer</p>
        </div>

        <div class="row">
          <div class="col-lg-4 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
            <div class="icon-box">
              <div class="icon"><i class="bi-gear-wide-connected"></i></div>
              <h4><a href="/">System Design</a></h4>
              <p>Give us your requirements and get back a suitable design for the system right away</p>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0" data-aos="zoom-in" data-aos-delay="200">
            <div class="icon-box">
              <div class="icon"><i class="bx bxs-devices"></i></div>
              <h4><a href="/">Software Development</a></h4>
              <p>We develop softwares applying rapid development methods to meet your requirements</p>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0" data-aos="zoom-in" data-aos-delay="300">
            <div class="icon-box">
              <div class="icon"><i class="bx bx-desktop"></i></div>
              <h4><a href="/">Web Development</a></h4>
              <p>Do not miss out on having an up-to-date dynamic website developed</p>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4" data-aos="zoom-in" data-aos-delay="100">
            <div class="icon-box">
              <div class="icon"><i class="bi-ui-checks"></i></div>
              <h4><a href="/">Product Management</a></h4>
              <p>Provide any kind of project to get the best management services from our capable experts.</p>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4" data-aos="zoom-in" data-aos-delay="200">
            <div class="icon-box">
              <div class="icon"><i class="bx bx-bot"></i></div>
              <h4><a href="/">Testing & Automation</a></h4>
              <p>Need a system tested? Creation of automated systems and custom web bots all available</p>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4" data-aos="zoom-in" data-aos-delay="300">
            <div class="icon-box">
              <div class="icon"><i class="bi-alexa"></i></div>
              <h4><a href="/">Graphic Design</a></h4>
              <p>All creative designs and high quality graphics covering all aspects</p>
            </div>
          </div>

        </div>

      </div>
    </section>
  )
}

export default Services