import React from 'react'
import Hero from '../base/Hero'

import About from './About'
// import Clients from './Clients'
import Contact from './Contact'
// import Counts from './Counts'
// import Cta from './Cta'
import Features from './Features'
// import Portfolio from './Portfolio'
import Services from './Services'
import Testimonials from './Testimonials'
import Team from './Team'


function Home() {
  return (
    <div>
      <Hero/>
      <main id="main">
        <About />
        {/* <Clients /> */}
        <Features />
        <Services />
        {/* <Cta /> */}
        {/* <Portfolio /> */}
        {/* <Counts /> */}
        <Testimonials />
        <Team />
        <Contact /> 
       </main>
    </div>
  )
}

export default Home