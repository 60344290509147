import React from 'react';
// Import Swiper React components
import { Autoplay, Pagination  } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';


const Team = () => {
  return (
    <section id="team" className="teams section-bg">
      <div className="container" data-aos="fade-up">
        <div className="row gy-4">

        <div className="section-header">
          <h2>Team</h2>
          <p>Check our Team</p>
        </div>

          <Swiper
          // install Swiper modules
            modules={[Autoplay, Pagination]}
            speed = {400}
            loop = {true}
            slidesPerView = {3}
            autoplay = {{
              delay: 5000,
              disableOnInteraction: false
            }}
            pagination={
                        {
                el: '.swiper1-pagination',
                type: 'bullets',
                // bulletClass: 'team-pagination-bullet',
                clickable: true
              }
            }
             breakpoints = {
              {  320: {
                slidesPerView: 1,
                spaceBetween: 40
              },
              480: {
                slidesPerView: 2,
                spaceBetween: 0
              },
              640: {
                slidesPerView: 2,
                spaceBetween: 80
              },
              860: {
                slidesPerView: 3,
                spaceBetween: 80
              }}
            }
            >

          <SwiperSlide className='col-lg-4 col-md-6 d-flex align-items-stretch slide1'>
          <div className="" data-aos="fade-up" data-aos-delay="100">
            <div className="team-member" data-aos="fade-up" data-aos-delay="400">
              <div className="member-img">
                <img src={require("../../assets/img/team/team-1.jpg")} className="img-fluid" alt="/"/>
                <div className="social">
                  <a target="_blank" rel="noreferrer" href="https://twitter.com/gitau_muruthi"><i className="bi bi-twitter"></i></a>
                  <a target="_blank" rel="noreferrer" href="https://www.facebook.com/david.muruthi.733"><i className="bi bi-facebook"></i></a>
                  <a target="_blank" rel="noreferrer" href="https://www.instagram.com/gitau_muruthi/"><i className="bi bi-instagram"></i></a>
                  <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/in/david-gitau-ke/"><i className="bi bi-linkedin"></i></a>
                </div>
              </div>
              <div className="member-info">
                <h4>David Gitau</h4>
                <span>CEO</span>
              </div>
            </div>
          </div>
          </SwiperSlide>

          <SwiperSlide className='col-lg-4 col-md-6 d-flex align-items-stretch slide1'>
          <div className="" data-aos="fade-up" data-aos-delay="100">
            <div className="team-member" data-aos="fade-up" data-aos-delay="400">
              <div className="member-img">
                <img src={require("../../assets/img/team/team-4.jpg")} className="img-fluid" alt="/"/>
                <div className="social">
                  <a href="/"><i className="bi bi-twitter"></i></a>
                  <a href="/"><i className="bi bi-facebook"></i></a>
                  <a href="/"><i className="bi bi-instagram"></i></a>
                  <a href="/"><i className="bi bi-linkedin"></i></a>
                </div>
              </div>
              <div className="member-info">
                <h4>Amanda Jepson</h4>
                <span>Accountant</span>
              </div>
            </div>
          </div>
          </SwiperSlide>

          <SwiperSlide className='col-lg-4 col-md-6 d-flex align-items-stretch slide1'>
          <div data-aos="fade-up" data-aos-delay="100">
            <div className="team-member" data-aos="fade-up" data-aos-delay="300">
              <div className="member-img">
                <img src={require("../../assets/img/team/team-3.jpg")} className="img-fluid" alt="/"/>
                <div className="social">
                  <a target="_blank" rel="noreferrer" href="https://twitter.com/erikmuturi"><i className="bi bi-twitter"></i></a>
                  <a target="_blank" rel="noreferrer" href="https://www.facebook.com/erick.muturi.73"><i className="bi bi-facebook"></i></a>
                  <a target="_blank" rel="noreferrer" href="https://www.instagram.com/mututieric/"><i className="bi bi-instagram"></i></a>
                  <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/in/erick-muturi-78147723b/"><i className="bi bi-linkedin"></i></a>
                </div>
              </div>
              <div className="member-info">
                <h4>Erick Muturi</h4>
                <span>CTO</span>
              </div>
            </div>
          </div>
          </SwiperSlide>
          
        </Swiper>
      <div className="swiper1-pagination section-header"></div>
        </div>
      </div>
    </section>
  )
}

export default Team